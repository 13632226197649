<template>
  <Toast />
  <div class="card">
    <div class="flex justify-content-between">
      <div class="flex align-items-center">
        <i class="pi pi-check-square mr-2" style="font-size: 1.5rem;"></i>
        <h3 style="margin:0px 5px;">{{ id ? 'Editar' : 'Cadastrar'}} Layout de Contrato</h3>
      </div>
      <Toast />
    </div>

    <hr />

    <div class="grid">
      <div class="col-12 sm:col-3 md:col-3 lg:col-3 xl:col-3">
        <label><strong>Tipo Contrato<span class="ml-1" style="color: red;">*</span></strong></label>
        <AutoComplete class="w-full mt-1" field="descricao" :dropdown="true" :forceSelection="true"
          v-model="dados.tipoContrato" :suggestions="tipoFiltro" @complete="buscarTipoContrato" />
      </div>

      <div class="col-12 sm:col-3 md:col-3 lg:col-3 xl:col-3">
        <label><strong>Nome do arquivo<span class="ml-1" style="color: red;">*</span></strong></label>
        <InputText class="w-full mt-1" :disabled="false" v-model="dados.nomeArquivo" id="nomeArquivo" />
      </div>

      <div class="col-12 sm:col-3 md:col-3 lg:col-3 xl:col-3">
        <label for="codigoRegional"><strong>Assinaturas do layout</strong></label>
        <div class="grid align-items-center form mt-1">
          <div class="col-6">
            <Checkbox id="assinatura" :binary="true" class="mr-2" v-model="dados.fielDepositario" />
            <label for="isReginal">Fiel Depositário</label>
          </div>

          <div class="col-6">
            <Checkbox id="assinatura" :binary="true" class="mr-2" v-model="dados.garanteSolidario" />
            <label for="isReginal">Garante Solidário</label>
          </div>
        </div>
      </div>

      <div class="col-12 sm:col-3 md:col-3 lg:col-3 xl:col-3">
        <label><strong>Hash do Arquivo</strong></label>
        <InputText class="w-full mt-1" :disabled="true" v-model="dados.hashArquivo" id="hashArquivo" />
      </div>

      <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
        <p class="mb-2 ml-1"><strong>Arquivo<span class="ml-1" style="color: red;">*</span></strong></p>
        <FileUpload name="demo[]" :showUploadButton="false" :maxFileSize="1000000" ref="anexos" @change="prepararAnexo">
          <template #empty>
            <span>Arraste e solte o arquivo aqui para anexar.</span>
          </template>
        </FileUpload>
      </div>
    </div>

    <div class="flex">
      <Button icon="pi pi-save" label="Salvar" class="p-button-info" @click="salvar()"></Button>

      <Button icon="pi pi-times" label="Cancelar" class="p-button-danger ml-2" @click="voltar()"></Button>
    </div>
  </div>
</template>

<script>
import ManutencaoContratoService from '../../service/ManutencaoContratoService';
import ManutencaoContrato from '../../service/ManutencaoContratoService';
import StorageService from '../../service/StorageService';
export default {
  props: {
    id: {
      type: Number,
      required: false
    }
  },

  data() {
    return {
      dados: {
        manutencaoContrato: {}
      },
      tipoFiltro: [],
      tipos: [
        { codigo: "A_FIXAR", descricao: "A Fixar" },
        { codigo: "FIXO", descricao: "Fixo" }
      ],

      campoInvalido: {},
    }
  },

  methods: {
    preencherForm(data) {
      this.dados = data.templateDocumento;
      this.dados["tipoContrato"] = this.tipos.find(item => { return item.codigo === data.templateDocumento.tipoContrato });
    },

    validarCampos(){
      let campos = [];

      if(!this.dados.tipoContrato) {
        campos.push("Tipo Contrato");
        this.campoInvalido.tipoContrato = true;
      }
      else {
        this.campoInvalido.tipoContrato = false;
      }

      if(!this.dados.hashArquivo && !this.dados.anexos) {
        campos.push("Arquivo");
        this.campoInvalido.anexos = true;
      }
      else {
        this.campoInvalido.anexos = false;
      }

      if(!this.dados.nomeArquivo) {
        campos.push("Nome do Arquivo");
        this.campoInvalido.nomeArquivo = true;
      }
      else {
        this.campoInvalido.nomeArquivo = false;
      }

      if(campos.length > 0) {
        let mensagem = `Por favor, preencher o campo: ${ campos[0] }.`;

        if(campos.length > 1) {
            mensagem = "Por favor, preencher os campos: ";
            mensagem += campos.join(", ");

            const indice = mensagem.lastIndexOf(", ");
            mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
        }

        this.$toast.add({
            severity: 'warn',
            summary: 'Não foi possível salvar o Layout.',
            detail: mensagem, 
            life: 15000
        });
      }

      return campos.length === 0;
    },

    salvar() {
      if(!this.validarCampos()) return;
      const dados = this.montarJson();
      ManutencaoContrato.salvar(dados).then(({ data }) => {
        if (!!data) {
          const detalhe = 'Layout salvo com sucesso!.';
          StorageService.setMensagemToast("MANUTENCAOCONTRATO", 'success', 'Operação realizada!', detalhe, true);
          this.voltar();
        }
      }).catch(error => {
        const toast = {
            severity: 'error',
            summary: 'Não foi possível salvar o Layout.',
            detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
            life: 7500
        }

        if(!!error && !!error.response && !!error.response.data) {
            const { message } = error.response.data;
            toast["detail"] = message;
        }

        this.$toast.add(toast);
      });
    },

    montarJson() {
      const obj = {};
      var fiel = this.dados.fielDepositario;
      var garante = this.dados.garanteSolidario;
      if (!fiel) {
        fiel = false;
      }

      if (!garante) {
        garante = false;
      }

      obj["id"] = this.id;
      obj["nomeArquivo"] = this.dados.nomeArquivo;
      obj["tipoContrato"] = this.dados.tipoContrato.codigo;
      obj["fielDepositario"] = fiel;
      obj["garanteSolidario"] = garante;
      if (!this.dados.anexos){
        obj["hashArquivo"] = this.dados.hashArquivo;
      } else {
        obj["hashArquivo"] = this.dados.anexos[0].base64;
      }
      obj["templateDocumento"] = this.dados.templateDocumento;


      return obj;
    },

    voltar() {
      this.$router.push(`/manutencao-contrato`);
    },

    async prepararAnexo() {
      const anexo = [];
      for (let file of this.$refs.anexos.files) {
        if (anexo.length < 2) {
          const base64 = await this.getBase64(file);
          anexo.push({
            nome: file.name,
            base64: base64
          });
        };
      }
      this.dados['anexos'] = anexo;
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function () {
          const result = reader.result;

          if (typeof result === 'string') {
            const base64 = result.substring(result.indexOf(',') + 1, result.length);
            return resolve(base64);
          }

          return resolve(result);
        };

        reader.onerror = function (error) {
          return reject(error);
        };

        reader.readAsDataURL(file);
      });
    },

    buscarTipoContrato(event) {
      if (!event.query.trim().length) {
        this.tipoFiltro = [...this.tipos];
      } else {
        this.tipoFiltro = this.tipos.filter((item) => {
          return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    }
  },

  mounted() {
    if (!!this.id) {
      ManutencaoContrato.buscarPorId(this.id).then(({ data }) => {
        this.preencherForm(data);
      })
        .catch(error => {
          this.dados = {};

          const toast = {
            severity: 'error',
            summary: `Falha ao buscar os dados do layout.`,
            detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!",
            life: 7500
          }

          if (!!error && !!error.response && !!error.response.data) {
            const { message, exception } = error.response.data;

            if (!!exception) {
              toast["detail"] = exception;
            }
          }

          this.$toast.add(toast);
        });
    }
  }
};
</script>
<style scoped>
.borda-personalizada {
  border: 1px solid #CCC;
  border-radius: 3px;
  padding: 0.5rem;
  margin-bottom: 15px;
  width: 58%;
}
</style>