<template>
    <div class="card">
        <div class="flex align-items-center">
            <i class="pi pi-building" style="font-size: 1.4rem"></i>
            <h3 style="margin:0px 5px;">Layout de Contrato</h3>
        </div>

        <hr />

        <DataTable 
            responsiveLayout="stack"
            :value="data" 
            v-model:selection="selecionado"
            selectionMode="single"
            showGridlines
            @sort="sort($event)">

            <template #header>
                <div class="flex align-items-center">
                    <div class="field mr-2">
                        <label>
                            <strong>Nome</strong>
                        </label>
                        <InputText id="nomeArquivo" class="w-full" v-model="filter.nomeArquivo"
                            @keyup.enter="filtrar()" />
                    </div>

                    <div class="field mr-2">
                        <label><strong>Tipo Contrato</strong></label>

                        <AutoComplete class="w-full reset mr-1" placeholder="Selecionar" id="tipoContrato"
                            field="descricao" :dropdown="true" :forceSelection="true" v-model="tipoContrato"
                            :suggestions="tipoFiltro" @complete="buscarTipo" />
                    </div>

                    <div class="mr-2 mt-2">
                        <div class="p-inputgroup">
                            <Button label="Filtrar" icon="pi pi-filter" class="p-button-outlined p-button-info"
                                @click="filtrar()" />

                            <Button title="Limpar Filtro" icon="pi pi-filter-slash"
                                class="p-button-outlined p-button-info" style="margin-left: 1px;"
                                @click="limparFiltro()"></Button>
                            <Button v-if="filter.situacao == 'ATIVO'" type="button" icon="pi pi-ban"
                                label="Listar inativos" class="p-button-outlined p-button-danger ml-1"
                                style="padding: 0.4rem;" @click="filter.situacao = 'INATIVO';
        filtrar()" />

                            <Button v-if="filter.situacao == 'INATIVO'" type="button" icon="pi pi-check-circle"
                                label="Listar ativos" class="p-button-outlined ml-1" style="padding: 0.4rem; " @click="filter.situacao = 'ATIVO';
        filtrar()" />
                        </div>
                    </div>

                    <Button label="Cadastrar" icon="pi pi-plus" class="p-button-info mt-2 mr-2"
                        @click="cadastrar()"></Button>
                </div>


            </template>
            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="id" header="ID" sortable/>
            <Column field="nomeArquivo" header="Nome Arquivo" sortable/>
            <Column field="tipoContrato" header="Tipo Contrato">
                <template #body="{ data, field }">
                    {{ data[field] == "A_FIXAR" ? "A Fixar" : "Fixo" }}
                </template>
            </Column>

            <Column header="Com Fiel Depositário">
                <template #body="{ data }">
                    <i v-if="data.fielDepositario" class="pi pi-check-circle"
                        style="font-size:1.2rem; color:green;"></i>
                </template>
            </Column>


            <Column header="Com Garante Solidário">
                <template #body="{ data }">
                    <i v-if="data.garanteSolidario" class="pi pi-check-circle"
                        style="font-size:1.2rem; color:green;"></i>
                </template>
            </Column>


            <Column header="Ações" style="width:10rem;" class="centralizar-titulo-tabela">
                <template #body="{ data }">
                    <div class="flex-none">
                        <Button title="Editar" icon="pi pi-pencil" class="p-button-rounded p-button-info flex-none mr-2"
                            @click="editar(data.id)"></Button>
                        <Button v-if="data.hashArquivo" title="Baixar Arquivo" icon="pi pi-print"
                            class="p-button-rounded p-button-success p-2 mr-2"
                            @click="gerarArquivo(data.hashArquivo, data.nomeArquivo)"></Button>
                        <Button v-if="data.ativo" icon="pi pi-ban"
                            class="p-button-rounded p-button-danger flex-none mr-2" title="Inativar"
                            @click="confirmarAtivarOuInativar(data)" />
                        <Button v-if="!data.ativo" icon="pi pi-check-circle"
                            class="p-button-rounded p-success flex-none mr-2" title="Ativar"
                            @click="confirmarAtivarOuInativar(data)" />

                    </div>
                </template>
            </Column>

        </DataTable>
        <Dialog v-model:visible="ativarOuInativarDialog" :style="{ width: '300px' }" header="Alerta" :modal="true">
            <div class="flex align-items-center justify-content-center">
                <span>Deseja <strong>{{ selecionado.ativo ? 'Inativar' : 'Ativar' }}</strong> o layout <strong>{{
            filter.nomeArquivo }}</strong>?</span>
            </div>
            <template #footer>
                <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarDialog = false" />
                <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger"
                    @click="ativarOuInativar()" />
            </template>
        </Dialog>
        <Paginator v-model:first="primeiraLinha" :rows="qtdRegistroPorPagina" :total-records="totalRegistro"
            :rowsPerPageOptions="[5, 10, 20, 30]" @page="onPage" />
    </div>
</template>
<script>
import { default as ManutencaoContrato, default as ManutencaoContratoService } from '../../service/ManutencaoContratoService';
import StorageService from '../../service/StorageService';

export default {
    data() {
        return {
            pagina: 0,
            primeiraLinha: 0,
            totalRegistro: 0,
            qtdRegistroPorPagina: 10,
            data: [],
            layoutSelecionado: null,
            tipoContrato: null,
            tipoFiltro: [],
            tipos: [
                { codigo: "A_FIXAR", descricao: "A Fixar" },
                { codigo: "FIXO", descricao: "Fixo" }
            ],

            tipoContratoSelecionado: null,
            tipoContratoFiltro: [],

            filter: {},
            ordenar: 'nomeArquivo,asc',
            manutencaoSelecionada: {},
            selecionado: {},
            ativarOuInativarDialog: false,

            storange: StorageService.getControlePagina("MANUTENCAOCONTRATO"),
            storangeMessage: StorageService.getMensagemToast("MANUTENCAOCONTRATO")
        }
    },

    created() {
        if (this.storange.default == undefined) {
            this.filter = this.storange.filtros;
        }

        this.pagina = this.storange.numero;
        this.primeiraLinha = this.storange.firstRow;
        this.qtdRegistroPorPagina = this.storange.qtdRegistro;
        this.filter = this.getFiltroPadrao();
        this.carregarDados();
        this.exibirMensagemToast();
    },

    methods: {
        exibirMensagemToast() {
            if (this.storangeMessage.active) {
                this.$toast.add({
                    severity: this.storangeMessage.severity,
                    summary: this.storangeMessage.summary,
                    detail: this.storangeMessage.detail,
                    life: 7500
                });
                StorageService.setMensagemToast("MANUTENCAOCONTRATO", 'info', '', '', false);
            }
        },

        getFiltroPadrao() {
            return { nomeArquivo: null, tipoContrato: undefined, situacao: 'ATIVO' };
        },

        cadastrar() {
            this.$router.push(`/manutencao-contrato/cadastrar`);
        },

        editar(idTemplate) {
            this.$router.push(`/manutencao-contrato/editar/${idTemplate}`);
        },

        gerarArquivo(event, nomeArquivo) {
            ManutencaoContratoService.baixarArquivo(event)
                .then(response => {
                    let fileName = nomeArquivo + '.rtf';
                    let urlBlobObject = window.URL.createObjectURL(new Blob([response.data], { type: 'application/rtf' }));
                    let link = document.createElement('a');
                    link.href = urlBlobObject;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(urlBlobObject);
                    this.$toast.add({
                        severity: 'success',
                        detail: `Download do arquivo realizado com sucesso!`,
                        life: 3000
                    });
                }).catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Não foi possível baixar o Layout.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        carregarDados() {
            this.filter["page"] = this.pagina;
            this.filter["size"] = this.qtdRegistroPorPagina;
            this.filter["sort"] = this.ordenar;

            if (!!this.nomeArquivo) {
                this.filter["nomeArquivo"] = this.nomeArquivo;
            } else {
                this.filter["nomeArquivo"] = undefined;
            }

            if (!!this.tipoContrato) {
                this.filter["tipoContrato"] = this.tipoContrato.codigo;
            } else {
                this.filter["tipoContrato"] = undefined;
            }

            ManutencaoContrato.buscarComPaginacao(this.filter)
                .then(({ data }) => {
                    if (data) {
                        this.data = data.content;
                        this.qtdRegistroPorPagina = data.pageable.pageSize;
                        this.totalRegistro = data.totalElements;
                    }
                }).catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao buscar Layouts',
                        detail: `Falha não mapeada.`,
                        life: 7500
                    };
                    if (!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
            StorageService.setControlePaginaFilter("MANUTENCAOCONTRATO", this.pagina, this.primeiraLinha, this.qtdRegistroPorPagina, this.filter);
        },

        onPage(event) {
            this.pagina = event.page + 1;
            this.primeiraLinha = event.rows * event.page;
            this.qtdRegistroPorPagina = event.rows;
            this.carregarDados();
        },

        sort(event) {
            const sortField = event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.ordenar = `${sortField},${sortOrder}`;
            this.carregarDados();
        },

        confirmarAtivarOuInativar(data) {
            this.layoutSelecionado = data;
            this.selecionado = data;
            this.ativarOuInativarDialog = true;
        },

        downloadoConcluido() {

        },

        filtrar() {
            this.pagina = 1;
            this.primeiraLinha = 0;
            this.carregarDados();
        },

        limparFiltro() {
            this.pagina = 1;
            this.primeiraLinha = 0;
            this.qtdRegistroPorPagina = 10;
            this.tipoContrato = null;
            this.filter = this.getFiltroPadrao();
            this.carregarDados();
        },

        buscarTipo(event) {
            if (!event.query.trim().length) {
                this.tipoFiltro = [...this.tipos];
            } else {
                this.tipoFiltro = this.tipos.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        ativarOuInativar() {
            if (this.filter.situacao == "ATIVO") {
                ManutencaoContratoService.inativar(this.layoutSelecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity: 'success',
                            detail: `O layout  ${this.layoutSelecionado.id} - ${this.layoutSelecionado.nomeArquivo}  foi inativado com sucesso!`,
                            life: 3000
                        });

                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                    })
                    .catch(error => {
                        this.$toast.add({
                            severity: 'warn',
                            detail: `Falha ao inativar o layout ${this.layoutSelecionado.id} - ${this.layoutSelecionado.nomeArquivo} !`,
                            life: 3000
                        });
                    });
            }
            else {
                ManutencaoContratoService.ativar(this.layoutSelecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity: 'success',
                            detail: `O layout ${this.layoutSelecionado.id}- ${this.layoutSelecionado.nomeArquivo} foi ativado com sucesso!`,
                            life: 3000
                        });

                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                    })
                    .catch(error => {
                        this.$toast.add({
                            severity: 'warn',
                            detail: `Falha ao ativar o layout ${this.layoutSelecionado.id}- ${this.layoutSelecionado.nomeArquivo} !`,
                            life: 3000
                        });
                    });
            }
        },

    }
}
</script>
