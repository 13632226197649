import axios from "axios";
import ApiUrl from "../config/ApiUrl";

class ManutencaoContratoService {
    salvar(data) {
        const config = {
            headers: {
                'content-type' : 'application/json'
            }
        }

        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/templatedocumento`,data ,config)

    }
    buscarComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10,  sort: 'asc' }) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/templatedocumento/buscar-com-paginacao`, { params });
    }
    ativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/templatedocumento/ativar/${ id }`);
    }
    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/templatedocumento/inativar/${ id }`);
    }

    buscarPorId(id) {
        return axios.get(`${ ApiUrl.vg}api/genesis-vg/v1/templatedocumento/${ id }`)
    }

    baixarArquivo(arquivo) {
        return axios.get(`${ ApiUrl.vg}api/genesis-vg/v1/templatedocumento/download/${ arquivo }`)
    }
}

export default new ManutencaoContratoService();